<template>
  <div class="home">
    <div>
      欢迎使用海捞代付
    </div>
    <div>
      联系电话：123456789
    </div>
    <router-link to="/manage">管理首页</router-link>
  </div>
</template>

<script>
export default ({
  data () {
    return {

    }
  },
  mounted() {
  }
})

</script>
<style>
.home{
  padding: 10px;
  text-align: center;
}
</style>