<template>
  <div style="">
    <div class="room-item" v-for="item in roomList" v-bind:key="item.roomId">
      <div style="display: flex;justify-content: space-between">
        <div>
          创建时间:{{ item.createTime }}
        </div>
        <div style="color: crimson;" @click="deleted(item.roomId)">删除</div>
      </div>
      <view>
        房间：{{ item.roomId }}
      </view>
      <van-row :size="40">
        <van-col span="11" @click="copyScan(item.roomId)">
          <van-button type="success" size="small" block>拷贝扫码链接</van-button>
        </van-col>
        <van-col span="11" offset="2" @click="copyPay(item.roomId)">
          <van-button type="warning" size="small" block>拷贝支付链接</van-button>
        </van-col>
      </van-row>
    </div>
    <div style="height: 160px"></div>
    <div style="position: fixed;left: 10px;bottom: 50px;width: calc(100% - 20px)">
      <van-button round block type="primary" @click="create">
        创建新房间
      </van-button>
      <div @click="loginOut" style="text-align: center;font-size: 12px;padding: 10px;">退出登录</div>
    </div>
  </div>
</template>
<script>


import {showToast} from "vant";
import clipboard from 'clipboard'
import {showConfirmDialog} from 'vant';

export default ({
  name: "AdminViews",
  data() {
    return {
      roomList: [],
      page: 1,
      finished: false,
      loading: true
    }
  },
  mounted() {
    const that = this
    // 浏览器触底加载功能的实现
    /* 1.视口的高度+页面被卷去的高度=滚动条的长度 */
    // window.addEventListener("scroll", function () {
    //   //页面被卷去的高度: window.scrollY
    //   //页面被卷去的高度: window.pageYOffset
    //   //页面被卷去的高度: document.documentElement.scrollTop
    //   // console.log("页面被卷去的高度:",window.scrollY,window.pageYOffset,document.documentElement.scrollTop);
    //
    //   // body页面的滚动条高度: document.body.scrollHeight
    //   // 整个页面你的滚动条高度: document.documentElement.scrollHeight
    //   // console.log(document.body.scrollHeight,document.documentElement.scrollHeight);
    //
    //   // 可视页面的高度: document.documentElement.clientHeight
    //   // console.log(document.documentElement.clientHeight);
    //
    //   if (document.documentElement.clientHeight + window.scrollY >= document.documentElement.scrollHeight) {
    //     console.log("触底了!!!!");
    //     that.page = that.page + 1
    //     that.getList()
    //   }
    //
    // })
    that.getList()
  },
  methods: {
    getList() {
      this.$api.get("/room/getRoomList?pageIndex=" + this.page)
          .then(res => {
            if (this.page == 1) {
              this.roomList = res
            } else {
              // console.log(res)
              if (res.length > 0) {
                var roomList = this.roomList
                roomList.comcat(res)
                this.roomList = roomList
              }
            }

          }).catch(msg => {
        showToast(msg);
      })
    },
    create() {
      this.$api.post("/room/create")
          .then(res => {
            res.data;
            this.getList()
          })
    },
    deleted(e) {
      console.log(e)
      showConfirmDialog({
        title: '删除确认',
        message:'确认要删除：'+e+' 吗？',
      }).then(() => {
        this.$api.post("/room/delete/"+e
        ).then(res => {
          res.data;
          showToast('删除成功！');
          this.getList()
        })
      }).catch(() => {
        // on cancel
      });
    },
    copyScan(e) {
      const domain = window.location.origin;
      // console.log(domain); // 输出当前域名
      clipboard.copy(domain + "/scan/" + e)
      showToast("拷贝成功！");
    },
    copyPay(e) {
      const domain = window.location.origin;
      clipboard.copy(domain + "/student/" + e)
      showToast("拷贝成功！");
    },
    loginOut(){
      localStorage.removeItem('token')
      showToast("退出登录");
      this.$router.push('/login');
    }
  }
})
</script>

<style scoped>
.room-item {
  padding: 10px;
  border-radius: 8px;
  background-color: #f1f1f1;
  margin-bottom: 10px;
  line-height: 30px;
  font-size: 14px;
}

van-col {
  text-align: center;
}
</style>