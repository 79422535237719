<template>
  <div>
    <div style="margin: 20px auto;text-align: center;">
      <video id="video"></video>
    </div>
    <div id="sourceSelectPanel" style="display:none;line-height: 20px;font-size: 14px;text-align: center;">

      <label for="sourceSelect">摄像头:</label>
      <select id="sourceSelect" style="max-width:200px">
      </select>
      <div style="color: #666666">请选择合适的摄像头扫码</div>
    </div>
    <div class="card">
      <div class="card-body" style="color: chocolate;">
        {{ roomInfo }}
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        店铺通知
      </div>
      <div class="card-body">
        <p class="card-text"></p>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        系统通知
      </div>
      <div class="card-body">
        <p class="card-text"></p>
      </div>
    </div>
  </div>
</template>

<script>

// WebRTC适配器 只需要引入就ok
import 'webrtc-adapter'
import {BrowserMultiFormatReader} from '@zxing/library'
import Socket from '@/utils/socket'
import { showSuccessToast } from 'vant';

var codeReader = null
var ws = null
export default ({
  name: "ScanView",
  data() {
    return {
      roomId: '',
      roomInfo: "",
      Invalid: false
    }
  },
  mounted() {

    codeReader = new BrowserMultiFormatReader();
    console.log("房间:" + this.$route.params.id)
    const that = this
    this.roomId = this.$route.params.id
    that.roomInfo = that.roomId + ":初始化中"
    var roomId = this.$route.params.id

    ws = new Socket({
      url: 'wss://www.dashenkeji.cn/api/topic/' + roomId,
      onOpen: e => {
        e
        that.roomInfo = that.roomId + ":连接中..."
      },
      onMessage: e => {
        // console.log(e)
        var data = JSON.parse(e)
        if (data.magType == 5) {
          that.roomInfo = that.roomId + ":已失效"
          window.location = "https://www.baidu.com/"
        } else if (data.magType == 1) {
          that.roomInfo = that.roomId + ":请扫描二维码..."
          this.openScan()
        }
      }
    })
    ws.connect()


  },
  methods: {
    openScan() {
      const that=this
      let selectedDeviceId;
      codeReader.listVideoInputDevices()
          .then((videoInputDevices) => {
            // 默认获取第一个摄像头设备id
            const sourceSelect = document.getElementById('sourceSelect')
            selectedDeviceId = videoInputDevices[0].deviceId

            if (videoInputDevices.length >= 1) {
              videoInputDevices.forEach((element) => {
                const sourceOption = document.createElement('option')
                sourceOption.text = element.label
                sourceOption.value = element.deviceId
                sourceSelect.appendChild(sourceOption)
              })

              sourceSelect.onchange = () => {
                selectedDeviceId = sourceSelect.value;
                codeReader.reset()
                that.decodeFromInputVideoFunc(selectedDeviceId)
              };
              const sourceSelectPanel = document.getElementById('sourceSelectPanel')
              sourceSelectPanel.style.display = 'block'
            }
            if (videoInputDevices.length > 1) {
              selectedDeviceId = videoInputDevices[1].deviceId
            }
            that.decodeFromInputVideoFunc(selectedDeviceId)
          })
          .catch((err) => {
            console.log(err)
          })
    },
    decodeFromInputVideoFunc(firstDeviceId) {
      codeReader.decodeFromInputVideoDeviceContinuously(
          firstDeviceId,
          'video',
          (result, err) => {
            if (result) {
              // console.log('扫描结果', result)
              var text = encodeURIComponent(result.text)
              var message = {
                "link": text,
                "roomId": this.roomId
              }
              ws.send(message)
              this.roomInfo = this.roomId + ":已发起支付！"
              showSuccessToast('已发起支付');
            }
            if (err && !err) {
              console.error(err)
            }
          }
      )
    }
  }
})
</script>

<style scoped>

#video {
  width: 300px;
  height: 300px;
}

.card {
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
}

.card-header {
  background-color: #f1f1f1;
  line-height: 40px;
  padding: 0 10px;
  font-size: 14px;
}

.card-body {
  min-height: 60px;
  padding: 10px;
  font-size: 14px;
}

.card-text {

}
</style>