import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ScanView from '../views/scan.vue'
import StudentView from '../views/student.vue'
import AdminViews from '../views/manage.vue'
import LoginViews from  '../views/login.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/scan/:id',
    name: 'ScanView',
    component: ScanView
  },{
    path: '/student/:id',
    name: 'studentView',
    component: StudentView
  },
  {
    path: '/manage',
    name: 'AdminViews',
    component: AdminViews
  },{
    path: '/login',
    name: 'LoginViews',
    component: LoginViews
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
