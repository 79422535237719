<template>
  <div>
    <div class="card">
      <div class="card-body">
        {{ roomInfo }}
      </div>
    </div>
    <div  style="padding: 20px 0">
      <van-button type="danger" block @click="goPay">如支付异常点击重新支付</van-button>
    </div>

    <div class="card">
      <div class="card-header">
        店铺通知
      </div>
      <div class="card-body">
        <p class="card-text"></p>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        系统通知
      </div>
      <div class="card-body">
        <p class="card-text"></p>
      </div>
    </div>
  </div>
</template>

<script>
import Socket from '@/utils/socket'

var ws = null
export default ({
  name: "studentView",
  data() {
    return {
      roomId: '',
      roomInfo: '',
      alipay: ''
    }
  },
  mounted() {
    console.log("房间:" + this.$route.params.id)
    const that = this
    var roomId = this.$route.params.id
    this.roomId = this.$route.params.id
    that.roomInfo = that.roomId + ":初始化中"
    ws = new Socket({
      url: 'wss://www.dashenkeji.cn/api/topic/' + roomId,
      onOpen: e => {
        e
        that.roomInfo = that.roomId + ":连接中..."
      },
      onMessage: e => {
        // console.log(e)
        var data = JSON.parse(e)
        if (data.magType == 5) {
          that.roomInfo = that.roomId + ":已失效"
          window.location ="https://www.baidu.com/"
        }else if (data.magType == 4) {
          if(that.alipay ==''){
            window.location = data.link
            that.alipay = data.link
          }
        }else {
          that.roomInfo = that.roomId + ":等待客户扫码..."
        }
      }
    })
    ws.connect()
  },
  methods: {
    goPay() {
      // window.location = this.alipay
        location.reload();
    }
  }
})


</script>

<style scoped>
.card {
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
}

.card-header {
  background-color: #f1f1f1;
  line-height: 40px;
  padding: 0 10px;
  font-size: 14px;
}

.card-body {
  min-height: 60px;
  padding: 10px;
  font-size: 14px;
}

.card-text {

}
</style>