import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
// 1. 引入你需要的组件
import {Button, Form, Field, CellGroup,Toast,Col, Row,List,Image as VanImage } from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';
router.beforeEach((to, form, next) => {
    // console.log(form)
    const token = localStorage.getItem('token')
    if (to.path == '/manage' && !token) {
        next('/login')
    } else {
        next()
    }
})
var doman="https://www.dashenkeji.cn/api";
// var doman="http://localhost:8888/api";
axios.interceptors.request.use(
    config => {
        // console.log(config)
        config.url=doman+config.url
        const token = localStorage.getItem('token')
        if(token){
            config.headers['token'] = token
        }
        return config;
    },
    error => {
        //请求错误
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(res => {
    // console.log(res.data)
    const {code, data, msg} = res.data
    if (code == 200) {
        return data
    } else if (code == 401) {
        router.replace('/login')
    }
    // alert(msg)
    return Promise.reject(msg)
})


const app = createApp(App)
app.config.globalProperties.$api = axios;
app.use(router);
//vant
app.use(Button).use(Form).use(Field).use(CellGroup).use(Toast).use(Col)
    .use(Row).use(List).use(VanImage)
app.mount('#app');
//添加请求拦截器
