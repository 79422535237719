<template>
  <div class="login">
    <div style="text-align: center;margin-bottom: 60px;">
      <img width="100px" height="100px" src="../assets/logo.png" alt="">
    </div>

    <van-form @submit="login">
      <van-cell-group inset>
        <van-field
            v-model="username"
            name="username"
            label="用户名"
            placeholder="用户名"
            :rules="[{ required: true, message: '请填写用户名' }]"
        />
        <van-field
            v-model="password"
            type="password"
            name="password"
            label="密码"
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]"
        />
      </van-cell-group>
      <div style="margin: 16px;margin-top: 40px">
        <van-button round block type="primary" native-type="submit">
          登录
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {showToast} from 'vant';

export default ({
  name: "LoginViews",
  data() {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    login(e) {
      this.$api.post("/admin/login", e).then((res) => {
        // console.log(res)
        localStorage.setItem('token', res.token)
        showToast("登录成功");
        this.$router.push('/manage');
      }).catch(msg => {
        showToast(msg);
      })
    }
  }
})
</script>

<style scoped>
.login {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
</style>