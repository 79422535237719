export default class Socket {
    constructor({ url, onMessage, onOpen, onError, onClose }) {
        this.url = url;
        this.onMessage = onMessage;
        this.onOpen = onOpen;
        this.onError = onError;
        this.onClose = onClose;
    }

    connect() {
        return new Promise((resolve, reject) => {
            const ws = new WebSocket(this.url);
            ws.onopen = () => {
                // console.log('open')
                typeof this.onOpen == 'function' && this.onOpen(ws);
                resolve(ws);
            };

            // 得到服务端发送过来的数据
            ws.onmessage = (e) => {
                // console.log(e)
                try {
                    const data = e.data
                    typeof this.onMessage == 'function' && this.onMessage(data);
                } catch (error) {
                    typeof this.onMessage == 'function' && this.onMessage(e.data);
                }
            };

            ws.onerror = (e) => {
                typeof this.onError == 'function' && this.onError(e);
                reject(e)
            };

            ws.onclose = () => {
                typeof this.onClose == 'function' && this.onClose();
            };
            this.ws = ws;
        });
    }

     async send(payload) {
        if (!payload || !this.ws) {
            if (!this.ws) {
                this.remainPayload.push(JSON.stringify(payload));
            }
            return;
        }
        if (this.isConnected) {
            this.ws.send(JSON.stringify(payload));
        } else {
            await this.connect();
            this.ws.send(JSON.stringify(payload));
        }
    }

     get isConnected() {
        return this.ws && this.ws.readyState === WebSocket.OPEN;
    }

     close() {
        if (this.ws) {
            this.ws.close();
            this.ws = undefined;
        }
    }
    openScoket(){
        const ws = new WebSocket(this.url);
        ws.onopen();
    }

}
